let winW = $(window).width();
let headH = $('#mainHeader').innerHeight();
let mBgH = $('body').innerHeight()-headH;
$(window).resize(function(){
    winW = $(window).width();
    headH = $('#mainHeader').innerHeight();
    mBgH = $('body').innerHeight()-headH;
    $('#mainHeader .menuBg').css({'top':headH,'height':mBgH});
})
$(document).ready(function(){
    /* Main Menu Start */
    $('#mainHeader.notLogged .mainNav .right a').click(function(e){
        if(winW>991){
            e.preventDefault();
            if($(this).hasClass('active')){
                $('#mainHeader.notLogged .mainNav .right a').removeClass('active')
            }else{
                $('#mainHeader.notLogged .mainNav .right a').removeClass('active')
                $(this).addClass('active')
            }
        }
    });
    $('#mainHeader .row .col-8 menu a.fa-bars,.menuBg').click(function(e){
        e.preventDefault();
        $('body').toggleClass('menuOpen');
        $('#mainHeader .row .col-8 menu .mainNav').slideToggle(300)
    })
    $('#mainHeader.loggedin .row .col-8 menu a.profile').click(function(e){
        e.preventDefault();
        $('#mainHeader.loggedin .row .col-8 menu .mainNav .right').slideToggle(300)
    })
    $('#mainHeader .menuBg').css({'top':headH,'height':mBgH});
    /* Main Menu End */
    
    /* Menu Login Validation Start */
    $('#menuLogin').click(function(e){
        e.preventDefault();
        let username = $('#loginemailPhone');
        let userinput = username.val();
        let email = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if(email.test(userinput) || userinput.match(phoneno) ){
            username.removeClass('error');
            username.parent().children('.error-text').fadeOut(0);
        } else{
            username.addClass('error');
            username.parent().children('.error-text').fadeIn();            
        }
        let password = $('#loginpassword');
        if(password.val().length <= 6 ){
            password.addClass('error');
            password.parent().children('.error-text').fadeIn();
        } else{
            password.removeClass('error');
            password.parent().children('.error-text').fadeOut(0);
        }
    });
    /* Menu Login Validation End */
    $('#menuLogin').click(function(e){
        e.preventDefault();
        let username = $('#loginemailPhone');
        let userinput = username.val();
        let email = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if(email.test(userinput) || userinput.match(phoneno) ){
            username.removeClass('error');
            username.parent().children('.error-text').fadeOut(0);
        } else{
            username.addClass('error');
            username.parent().children('.error-text').fadeIn();            
        }
        let password = $('#loginpassword');
        if(password.val().length <= 6 ){
            password.addClass('error');
            password.parent().children('.error-text').fadeIn();
        } else{
            password.removeClass('error');
            password.parent().children('.error-text').fadeOut(0);
        }
    });
    /* Login Page Validation Start */
    $('#loginBtn').click(function(e){
        e.preventDefault();
        let username = $('#mainlogusername');
        let userinput = username.val();
        let email = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if(email.test(userinput) || userinput.match(phoneno) ){
            username.removeClass('error');
            username.parent().children('.error-text').fadeOut(0);
        } else{
            username.addClass('error');
            username.parent().children('.error-text').fadeIn();            
        }
        let password = $('#mainlogpassword');
        if(password.val().length <= 6 ){
            password.addClass('error');
            password.parent().children('.error-text').fadeIn();
        } else{
            password.removeClass('error');
            password.parent().children('.error-text').fadeOut(0);
        }
    });
    /* Login Page Validation End */
});
